import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './menu.scss';

export default props => {
  return (
    <Menu right>
      <a className="menu-item" href="mailto:info@hedral.co">
        Contact
      </a>
      <a className="menu-item" href="https://jobs.polymer.co/hedral">
        Careers
      </a>
    </Menu>
  );
};