import React from 'react'
import './waitlist.scss'
import 'normalize-css'
import 'skeleton-css-webpack';
import logo from "../../assets/white_logo.png"
import { Link } from 'react-router-dom';

export default function Waitlist() {
  const onSubmitForm = (event) => {
    const form = event.target;
    form.style.display = 'none';
    event.preventDefault();

    const success = document.getElementById('success');
    success.style.display = 'block';
  };

  return (
    <div>
    {/* Basic Page Needs
–––––––––––––––––––––––––––––––––––––––––––––––––– */}
    <meta charSet="utf-8" />
    <title>Hedral</title>

    {/* Mobile Specific Metas
–––––––––––––––––––––––––––––––––––––––––––––––––– */}
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    {/* FONT
–––––––––––––––––––––––––––––––––––––––––––––––––– */}
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="//fonts.googleapis.com/css2?family=Manrope:ital,wght@0,300;1,300;1,900&display=swap" rel="stylesheet" />
    <link href="//fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;1,300;1,900&display=swap" rel="stylesheet" />
    {/* CSS
–––––––––––––––––––––––––––––––––––––––––––––––––– */}
    <link rel="stylesheet" href="css/normalize.css" />
    <link rel="stylesheet" href="css/skeleton-modwidth.css" />
    <link rel="stylesheet" href="css/waitlist.css" />
    {/* JS
–––––––––––––––––––––––––––––––––––––––––––––––––– */}
    <div className="vertical">
      {/* Left side of page here */}
      <div className="leftW" />
      {/* Right side of page here */}
      <div className="rightW">
        <div className="right-wrapper"> 
        <Link to="/">
          <img className="w_logo" src={logo} alt="logo" />
        </Link>
          <p className="large-text"> Get access to Hedral</p>
          <p className="body-textW"> Request access to Hedral including an onboarding demo from the team. </p>
          {/* Access form here */}
          <div className="access-form">
            <form onSubmit={onSubmitForm} name="form" id="form" method="POST" className="access-form-container" action='https://n6aczjswpd.execute-api.us-east-2.amazonaws.com/default/onFormSubmit'>
              <div className="access-form-flex-wrapper">
                <div id ="form-text">
                  <input type="text" className="name-text-field" maxLength={256} placeholder="First name" id="First-Name" />
                </div>
                <div id = "form-text">
                  <input type="text" className="name-text-field" maxLength={256} placeholder="Last name" id="Last- Name" />
                </div>
              </div>
              <div id = "waitlist" className="access-form-flex-wrapper">
                <input type="email" maxLength={256} placeholder="Work email" id="email-input2" required />
              </div>
              <div  id = "form-text" className="access-form-flex-wrapper">
                <input type="text" className="use-case-text-field" maxLength={256} placeholder="How do you want to use Hedral?" id="Use-Case" required />
              </div>
              <div id = "waitlist" className="access-form-flex-wrapper">
                <input type="submit" value="Join the waitlist" data-wait="Please wait..." />
              </div>
            </form>
            <div id="success" className="success-form-block" tabIndex={-1}>
              <p className="success-text"> You've been added to the list!</p>
            </div>
          </div>
          {/* Footer text here */}
          <footer className="footer">
            <div className="footer-wrapper">
              <p className="footer-log-in-text"> Already have an account?  &nbsp;</p>
              <a className="footer-log-in-text" href="https://prod.hedral.co/projects">Log in</a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
  );
}
