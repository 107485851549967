import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/homePage/home'
import Waitlist from './pages/waitlistPage/waitlist'
import Menu from './components/menu/menu';


class App extends Component {
  render() {
    return (
      <div className="App">
        <Menu pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <Router>
          <Routes>
            <Route path="/" element={ <Home/> } />
            <Route path="/access" element={ <Waitlist/> } />
          </Routes>
        </Router>
      </div>
    )
  }
}

export default App;
