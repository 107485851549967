import React from 'react'
import './home.scss'
import 'normalize-css'
import 'skeleton-css-webpack';
import { useNavigate } from 'react-router-dom';

/*assets*/
import logo from "../../assets/white_logo.png"

function changeTextOnHover() {
  const careers = document.getElementById('careers');
  const contact = document.getElementById('contact');
  const logIn = document.getElementById('log-in');
  const titleText = document.querySelector('.title-text');
  const bodyText = document.querySelector('.body-text');
  const textWrapper = document.querySelector('.text-wrapper');
  const bodyTextContent = 'From ancient monuments to modern skyscrapers, we have always blended art and science to craft our environment. Hedral supercharges domain experts with cutting edge technology to bring AEC work into a new age.'
  
  function addHoverEvents(type, textContent) {
    type.addEventListener('mouseover', () => {
      bodyText.textContent = bodyTextContent;
      const newText = document.createElement('p');
      newText.classList.add('change-text', 'body-text');
      newText.textContent = textContent;
      bodyText.appendChild(newText);
      // Triggering a reflow to ensure the transition effect
      void newText.offsetWidth;
      newText.style.opacity = 0.5;
      textWrapper.parentNode.classList.add('hovered');
    });
    
    type.addEventListener('mouseleave', () => {
      bodyText.textContent = bodyTextContent;
      textWrapper.parentNode.classList.remove('hovered');
    });
  }
  addHoverEvents(careers, "Join us on the frontier and make your mark.");
  addHoverEvents(contact, "Find out more about what we're buidling.");
  addHoverEvents(logIn, "Log in to the platform.");
}

export default function Home() {
  React.useEffect(() => {
    changeTextOnHover();
  }, []);
  
  const navigate= useNavigate();
  const handleWaitlistSubmit = event => {
    event.preventDefault();
    navigate('/access');
  };


  return (
    <div>
      <meta charSet="utf-8" />
      <title>Hedral</title>
      <meta name="Hedral" content="Coming 2022" />
      <meta name="BC" content />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="//fonts.googleapis.com/css2?family=Manrope:ital,wght@0,300;1,300;1,900&display=swap" rel="stylesheet" />
      <link href="//fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,300;1,300;1,900&display=swap" rel="stylesheet" />

      <link rel="stylesheet" href="css/normalize.css" />
      <link rel="stylesheet" href="css/skeleton-modwidth.css" />
      <link rel="stylesheet" href="css/custom.css" />

      <div className="page-wrapper">

        <header className="header">
          <div>
            <img src={logo} className="logo" alt = "logo"/>
            <nav className="navbar">
              <ul className="ul">
                <li className="li"><a className="li-a" href="https://prod.hedral.co/" id="log-in">Log In</a></li>
                <li className="li"><a className="li-a" href="mailto:info@hedral.co" id="contact">Contact</a></li> 
                <li className="li"><a className="li-a " href="https://jobs.polymer.co/hedral" id="careers"> Careers</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <main className="main">
          <div className="left"> 
            <div>
              <div className="pic" id="pic4" />
              <div className="pic" id="pic3" />
              <div className="pic" id="pic2" />
              <div className="pic" id="pic1" />
            </div>
          </div>
          <div className="right">
            <div className="text-wrapper">
              <p className="title title-text">Establishing the <span style={{color: '#236371'}}>new standard</span> for building design</p>
              <p className="body_text body-text" id="body-text"> From ancient monuments to modern skyscrapers, we have always blended art and 
                science to craft our environment. Hedral supercharges domain experts with cutting edge technology to bring AEC work into a new age.
              </p>
            </div>
          </div>
        </main>
        <footer className="footer">
          <div id="home" className="wrapper">
            <form onSubmit={handleWaitlistSubmit} name="email" id="email-form" method="GET" className="email-form-container">
              <div>
                <input type="email" maxLength={256} placeholder="Enter your email" id="email-input" className="email-form body-text" required />
              </div>
              <div id = "home"> 
                <input type="submit" Value="Join waitlist" data-wait="Please wait..." className="waitlist"/>
              </div>
            </form>
          </div>
          <p className="footer-text body-text">Hedral is operating in stealth. If you're interested in learning more, 
            contact us directly or join the email list. </p>
        </footer>
      </div>
    </div>
  );
}
